import "./Contacts.scss";

import { FiPhoneCall } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { Ri24HoursFill } from "react-icons/ri";
import { FiInstagram } from "react-icons/fi";
import { FaYelp } from "react-icons/fa";

const Contacts = () => {
  return (
    <div className="contacts">
      <ul>
        <li>
          <h3 className="hover">
            <a
              href="https://www.instagram.com/p/CTaA8pylj2w/?utm_medium=copy_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiInstagram /> electrolysis_by_kolyan
            </a>
          </h3>
        </li>

        <li>
          <a
            href="https://www.yelp.com/biz/electrolysis-by-kolyan-glendale-2?uid=-ZRjImbifUSUIoFWRe5N1A&utm_source=ishare"
            rel="noopener noreferrer"
            target="_blank"
          >
            <h3 className="hover">
              <FaYelp /> Electrolysis By Kolyan
            </h3>
          </a>
        </li>

        <li>
          <a href="tel:+17472727039">
            <h3 className="hover">
              <FiPhoneCall /> CALL US
            </h3>
            <p>(747) 272 - 7039 </p>
          </a>
        </li>
        <li>
          <h3>
            <GoLocation /> LOCATION
          </h3>
          <p>
            435 Arden Avenue, Suite 560
            <br></br>
            Glendale, CA 91203
          </p>
        </li>
        <li>
          <h3>
            <Ri24HoursFill /> BUSINESS HOURS
          </h3>
          <p>
            Mon-Fri .... 9 am - 6 pm,
            <br></br>
            Sat,Sun ...... Closed
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Contacts;

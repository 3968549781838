import "./Nav.scss";

import { useContext } from "react";
import { Link } from "react-router-dom";

import { routes } from "../../constants/routes.js";
import { LocationContext } from "../../App";


const Nav = () => {
  const [location] = useContext(LocationContext)

  return (
    <nav>
      <ul >
        <li className={location === routes.home.path ? "active" : ""}>
          <Link to={routes.home.path}>Home</Link>
        </li>
        <li
          className={location === routes.whyElectrolysis.path ? "active" : ""}
        >
          <Link to={routes.whyElectrolysis.path}>Why Electrolysis</Link>
        </li>
        <li className={location === routes.service.path ? "active" : ""}>
          <Link to='/service'>Service Prices</Link>
        </li>
        <li className={location === routes.contact.path ? "active" : ""}>
          <Link to={routes.contact.path}>Contact</Link>
        </li>
      </ul>
      
    </nav>
  );
};

export default Nav;

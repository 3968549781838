import "./Header.scss";
import ImageHeader from "../../assets/images/section-one.jpg";

const Header = () => {
  return (
    <header className='page-header'>
      <div className="companyName">
        <div>Electrolysis </div>
        <div className="row">
          <span className="by">by</span> Kolyan
        </div>
      </div>

      <div className="imgContainer">
        <div className="circle"> </div>
        <img src={ImageHeader} alt="main" />
      </div>

      <div className="header_status">
        <span className="borderedCircle"></span>
        <span className="status">
          Say goodbye to <br></br> unwanted hair forever !
        </span>
      </div>
    </header>
  );
};

export default Header;

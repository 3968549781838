import { createContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { routes } from "./constants/routes.js";

import Nav from "./components/navBar/Nav";
import ServicePageV2 from "./pages/servicePageV2/ServicePageV2.jsx";

export const LocationContext = createContext()

function App() {

  const [location, setLocation] = useState()
  
  return (
    <>
    <LocationContext.Provider value={[location, setLocation]} >
      <Router>
        <Nav />
        <Switch>
          <Route
            exact
            path={routes.home.path}
            children={routes.home.component}
          />
          <Route
            path={'/service'}
            children={<ServicePageV2 />}
          />
          <Route
            path={routes.contact.path}
            children={routes.contact.component}
          />
          <Route
            path={routes.whyElectrolysis.path}
            children={routes.whyElectrolysis.component}
          />
        </Switch>
      </Router>
      </LocationContext.Provider>
    </>
  );
}

export default App;

import "./According.scss";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

const According = ({
  title,
  id,
  text,
  setOpenedTextId,
  setIsVisible,
  isVisible,
}) => {
    
  const handleClick = () => {
    setIsVisible(!isVisible);
    setOpenedTextId(id);
  };

  return (
    <div className="according">
      <div onClick={handleClick} className="header">
        <span className="title">{title}</span>
        <span className="icon">
          {isVisible ? <FiArrowUp /> : <FiArrowDown />}
        </span>
      </div>
      {isVisible && <div className="text">{text}</div>}
    </div>
  );
};

export default According;

import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import "./WhyElectrolysis.scss";

import According from "../../components/according/According";
import Image from "../../assets/images/FAQ.png";
import { LocationContext } from "../../App";

const WhyElectrolysis = () => {
  const data = [
    {
      title: "What is Electrolysis ?",
      text: "Electrolysis is the permanent removal of unwanted hair by sliding a tiny probe, the approximate thickness of a hair, down the hair shaft to the root of the hair where a quick pulse of current is applied, resulting in the coagulation of the blood supply to the hair and the destruction of the root.",
    },
    {
      title: "Is electrolysis permanent ?",
      text: "Yes. The FDA and AMA recognize electrolysis as the only proven method of permanent hair removal. Once the blood supply to the hair has been destroyed at the root, hair will no longer grow from that hair follicle.",
    },
    {
      title: "Where on the body can electrolysis be done ?",
      text: "Electrolysis can be done on any part of the face and body where there is unwanted hair, except the inside of the nose and the inside of the ear.",
    },
    {
      title: "Does electrolysis hurt ?",
      text: "Generally, electrolysis does not hurt, but certain parts of the body are more sensitive to the current than others - for instance, the upper lip. For more sensitive areas, or for people with lower discomfort thresholds, there are various topical anesthetic creams that can be used to numb the area(s) to be treated.",
    },
    {
      title: "Will I be red after the treatment ?",
      text: "There may be some redness in the area. In the case of the upper lip, particularly if extensive work has been done, some people may experience mild puffiness for a short while. Usually, any reaction is gone in an hour or two.",
    },
    {
      title: "How long do the treatments usually take ?",
      text: "Treatments usually range from 15 minutes to an hour, depending on the amount of the hair that must be removed and the person`s tolerance for a longer session.",
    },
    {
      title: "Will there be any permanent marks on the treated area ?",
      text: "No. In the hands of a well-trained and skilled electrologist, whose instructions for after-care are followed, there should be no lasting marks or scars.",
    },
    {
      title: "Will there be regrowth ?",
      text: "Some regrowth is possible, particularly with (telogen) hairs that were not in the best stage for permanent removal (the best stage is the growing or anagen stage).",
    },
    {
      title: "Are there any situations when electrolysis should not be performed ?",
      text: "Yes, Your electrologist should do a consultation with you and ask the relevant medical questions to determine if you are a suitable candidate. A few situations require a doctor`s note of permission before commencing your treatment.",
    },
  ];

  const [openedTextId, setOpenedTextId] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  let {
    location: { pathname },
  } = useHistory();

  const [ ,setLocation] = useContext(LocationContext)
  
  useEffect(()=>{
    setLocation(pathname)
  },[setLocation, pathname])

  return (
    <div className="faq">
      <img className="img" src={Image} alt="faq" />
      <div className="according-container">
        {data.map((el, i) => {
          return (
            <According
              key={i}
              id={i}
              setIsVisible={setIsVisible}
              setOpenedTextId={setOpenedTextId}
              isVisible={openedTextId === i ? isVisible : false}
              title={el.title}
              text={el.text}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WhyElectrolysis;

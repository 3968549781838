import "./Footer.scss";

import Contacts from "../contacts/Contacts";

const Footer = () => {
  return (
    <footer className='footer'>
      <Contacts />
      <div className="map-container">
        <iframe
          title="map-address"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=435%20Arden%20Avenue%2C%20Suite%20560%2C%20Glendale%2C%20CA%2091203&t=&z=13&ie=UTF8&iwloc=&output=embed"
          className="map"
        ></iframe>
        <a href="https://putlocker-is.org">{''}</a>
      </div>
    </footer>
  );
};

export default Footer;

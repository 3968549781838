import { Link } from "react-router-dom";
import "./SectionElectrolysis.scss";
import { routes } from "../../constants/routes";
import ImageWhyElectrolysis from '../../assets/images/legs.jpg'

const SectionElectrolysis = () => {
  
  return (
    <section className="chooseElectrolysis">
      <div className="text">
        <h2>What is electrolysis and <br/> why choose it ?</h2>
        <ul>
          <li>The only FDA approved method for permanent hair removal</li>
          <li>Effective on all skin tones and hair colors</li>
          <li>
            Effective on all hair types including straight, curly and kinky{" "}
          </li>
          <li>Safe and sterile </li>
          <li>Results are permanent </li>
        </ul>
        <div className="text-footer">
          <button className="learnMoreBtn">
            <Link to={routes.whyElectrolysis.path}>Learn More</Link>
          </button>
        </div>
      </div>
      <div className="imgContainer">
        <img src={ImageWhyElectrolysis} alt="why electrolysis" />
      </div>
    </section>
  );
};

export default SectionElectrolysis;

import { useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import "./HomePage.scss";
import Header from "../../components/header/Header";
import AboutMe from "../../components/aboutMe/AboutMe";
import Footer from "../../components/footer/Footer";
import SectionElectrolysis from "../../components/sectionElectrolysis/SectionElectrolysis";
import SectionServices from "../../components/sectionServices/SectionServices";
import { LocationContext } from "../../App";
import SectionMethodsV2 from "../../components/sectionMethodsV2/SectionMethodsV2";

const HomePage = () => {
  
  let {
    location: { pathname },
  } = useHistory();

  const [ ,setLocation] = useContext(LocationContext)
  
  useEffect(()=>{
    setLocation(pathname)
  },[setLocation, pathname])

  return (
    <div>
      <Header />
      <SectionElectrolysis />
      <SectionMethodsV2 />
      <SectionServices />
      <AboutMe />
      <Footer />
    </div>
  );
};

export default HomePage;

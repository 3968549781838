import "./SectionMethodsV2.scss";

const SectionMethodsV2 = () => {
  
  return (
    <section className="sectionMethodsV2">
     <h2>
     What methods does the electrolysis have ?
     </h2>
         <ul>
         <p>There are three recognized modalities (methods) of electrolysis:</p>
             <li>
                 <b>Thermolysis (high frequency) electrolysis</b> - the hair root is destroyed by heat production.
             </li>
             <li>
                 <b>Galvanic (direct current) electrolysis</b> - the hair root is destroyed by chemical action.
             </li>
             <li>
                 <b>Blend electrolysis</b> - a combination of the above two modalities where the hair 
                 root is destroyed by both heat and chemical action at the same time.
             </li>
         </ul>
         <footer>
         We use all three methods of electrolysis at Electrolysis_by _Kolyan.   
         They are all safe and effective. 
         Based on your needs, we will choose the modality that is best for you.
         </footer>
    </section>
  );
};

export default SectionMethodsV2;

import { Link } from "react-router-dom";
import "./SectionServices.scss";
import { bookAppointmentUrl, routes } from "../../constants/routes";
import ImageSectionServices from "../../assets/images/section-two.jpg";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

const services = [
  "Eyebrow Shaping",
  "Face",
  "Under Arms",
  "Chest",
  "Bikini",
  "Upper Lip",
  "Arms",
  "Stomach",
  "Back and Shoulders",
  "Legs",
];

const SectionServices = () => {
  return (
    <section className="sectionServices">
      <div className="imgContainer">
        <img src={ImageSectionServices} alt="services" />
      </div>
      <div className="text">
        <h2>Services</h2>
        <ul>
          {services.map((service, i) => {
            return <li key={i}>{service}</li>;
          })}
        </ul>
        <div className="text-footer">
          <span style={{ display: "flex", alignItems: "center" }}>
            <button
              className="bookAppointment"
              onClick={() => window.open(bookAppointmentUrl, "_blank")}
            >
              Book Appointment
            </button>
            <FaInfoCircle
              id="cancelationTooltip"
              style={{ marginLeft: "4px", cursor: "pointer" }}
              size={22}
              color="red"
            />
            <Tooltip
              anchorSelect="#cancelationTooltip"
              content={
                <div>
                  <h3>Cancelation/No show policy</h3>
                  <p style={{ textAlign: "justify", fontSize: "16px" }}>
                    Your treatment is reserved especially for YOU. At
                    Electrolysis by AK we value your business and understand
                    that scheduling changes may occur. We respectfully request
                    24 hour notice for any cancellations or rescheduling. Less
                    than 24 hours notice will result in a fee of 50% of the
                    scheduled appointment time. Late arrival or early
                    termination of service is not pro-rated.
                  </p>
                  <h4>Payments are due at the time of treatment.</h4>
                </div>
              }
              style={{ width: "280px", zIndex: "99" }}
            />
          </span>
          <button className="learnMoreBtn">
            <Link to={routes.service.path}>Pricing</Link>
          </button>
        </div>
      </div>
    </section>
  );
};

export default SectionServices;

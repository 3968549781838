import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { LocationContext } from "../../App";
import { bookAppointmentUrl } from "../../constants/routes";
import "./ServicePageV2.scss";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

const ServicePageV2 = () => {
  let {
    location: { pathname },
  } = useHistory();

  const [, setLocation] = useContext(LocationContext);

  useEffect(() => {
    setLocation(pathname);
  }, [setLocation, pathname]);

  return (
    <div className="servicePageV2">
      <div className="header">
        <h1>Service Prices</h1>
        <span style={{ display: "flex", alignItems: "center" }}>
          <button onClick={() => window.open(bookAppointmentUrl, "_blank")}>
            BOOK APPOINTMENT
          </button>
          <FaInfoCircle
            id="cancelationTooltipPricing"
            style={{ marginLeft: "14px", cursor: "pointer" }}
            size={26}
            color="red"
          />
          <Tooltip
            anchorSelect="#cancelationTooltipPricing"
            content={
              <div>
                <h3>Cancelation/No show policy</h3>
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                  Your treatment is reserved especially for YOU. At Electrolysis
                  by AK we value your business and understand that scheduling
                  changes may occur. We respectfully request 24 hour notice for
                  any cancellations or rescheduling. Less than 24 hours notice
                  will result in a fee of 50% of the scheduled appointment time.
                  Late arrival or early termination of service is not pro-rated.
                </p>
                <h4>Payments are due at the time of treatment.</h4>
              </div>
            }
            style={{ width: "280px", zIndex: "99" }}
          />
        </span>
      </div>
      <div className="container">
        <div className="row">
          <div className="borderedCircle">
            <i className="time">
              15 <i className="min-fee">min</i>
            </i>
            <i className="min-fee"> or less </i>
          </div>
          <div className="price"> 30 $ </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="borderedCircle">
            <i className="time">30</i> <i> min </i>
          </div>
          <div className="price"> 60 $ </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="borderedCircle">
            <i className="time">45</i> <i> min </i>
          </div>
          <div className="price"> 90 $ </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="borderedCircle">
            <i className="time">60</i> <i> min </i>
          </div>
          <div className="price"> 120 $ </div>
        </div>
        <hr></hr>
        <div className="row">
          <i className="bikini-text">Bikini area</i>
          <div className="price"> 170 $ </div>
        </div>
        <hr></hr>
      </div>
    </div>
  );
};

export default ServicePageV2;

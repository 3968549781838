import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import emailjs from "emailjs-com";
import "./ContactPage.scss";
import Contacts from "../../components/contacts/Contacts";
import { LocationContext } from "../../App";

const ContactPage = () => {
  let {
    location: { pathname },
  } = useHistory();
  const [, setLocation] = useContext(LocationContext);
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    emailjs
      .sendForm(
        "service_m4ycwrj",
        "template_0e8j8ur",
        form.current,
        "user_nEyVYLjEnLAv7VB5kmY3x"
      )
      .then(
        (res) => {
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          setError(true);
        }
      );
  };

  useEffect(() => {
    setLocation(pathname);
  }, [setLocation, pathname]);

  return (
    <div className="contactPage">
      <div className="container">
        <Contacts />
      </div>

      <div className="form-container">
        <h2> CONTACT US </h2>
        <form ref={form} onSubmit={sendEmail}>
          <input
            required
            type="text"
            name="name"
            placeholder="Enter your Name"
          />
          <input
            required
            type="email"
            name="email"
            placeholder="Enter a valid email address"
          />
          <input
            type='tel'
            name="tel"
            placeholder="Enter your phone number"
          />
          <textarea
            required
            placeholder="Enter a message"
            name="message"
            rows={4}
          />
          <input
            type="submit"
            value={
              loading
                ? "Sending your message ..."
                : error
                ? "Please try again"
                : "Send"
            }
          />
        </form>
      </div>
    </div>
  );
};

export default ContactPage;

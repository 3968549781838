import "./AboutMe.scss";
import ImageAbout from "../../assets/images/aboutMe-image.jpg";

const AboutMe = () => {
  return (
    <section className="aboutMe">
      <h1>About me </h1>
      <div className="imgContainer">
        <img src={ImageAbout} alt="my"></img>
      </div>
      <p className="text">
        Azniv Kolyan is the founder of Electrolysis by AK, INC. She is licensed
        with 15 years of experience. Making people feel good in their skin was
        always a passion, and now is also her career. A career that stemmed out
        of her home country Armenia, and continues to this day in Los Angeles.
        Her studies in the field have helped hone her skills, and have helped
        her become one of the leaders in the industry.
      </p>
    </section>
  );
};

export default AboutMe;

import ContactPage from "../pages/contactPage/ContactPage"
import HomePage from "../pages/homePage/HomePage"
import ServicePageV2 from "../pages/servicePageV2/ServicePageV2"
import WhyElectrolysis from "../pages/whyElectrolysis/WhyElectrolysis"

export const routes = {
    home: {
        path: '/',
        component: <HomePage />,
    },
    service: {
        path: '/service',
        component: <ServicePageV2 />,
    },
    contact: {
        path: '/contact',
        component: <ContactPage />,
    },
    whyElectrolysis: {
        path: '/whyElectrolysis',
        component: <WhyElectrolysis />,
    },
       
}

export const bookAppointmentUrl = "https://square.site/book/HC69W62RQCTDE/electrolysis_by_kolyan-glendale-ca"     